<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <h4>1) Select the type of instruction to create</h4>
            </v-col>
        </v-row>
        <v-row dense class="pt-3">
            <v-col cols="12">
                <v-select
                    outlined
                    dense
                    v-model="instructionType"
                    :items="instructionTypesList"
                    item-text="name"
                    item-value="value"
                    @change="$emit('updateInstructionType',instructionType)"
                >

                </v-select>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: "InstructionTypeSelector",
        props: {
            instructionType: {
                type: String,
                default: "ManualAssembly"
            },
        },
        data() {
            return {
                instructionTypesList: [
                    { name: "Manual - Assembly", value: "ManualAssembly" },
                    { name: "Manual - Product", value: "ManualProduct" },
                    { name: "Production Plan", value: "ProductionPlan" },
                ]
            }
        },
    }
</script>