<template>
    <v-container>
        <v-row>
            <v-col lg="12">
                <v-card-title>
                    <h2>Production Instructions</h2>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" to="/new-production-instructions">New Instruction <v-icon small right>mdi-plus</v-icon></v-btn>
                </v-card-title>
                <v-card>
                    <!-- Filters -->
                    <v-container fluid>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="selectedWorkCenters"
                                    :items="workCenters"
                                    item-text="work_center_name"
                                    item-value="work_center_name"
                                    label="Filter by Work Center"
                                    return-object
                                    clearable
                                    prepend-icon="mdi-filter-variant"
                                    outlined
                                    dense
                                    multiple
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                dense
                                :items="filteredInstructions"
                                :headers="tableHeaders"
                                :items-per-page="500"
                                show-expand
                                single-expand
                                item-key="instruction_id"
                                group-by="instruction_work_center.work_center_name"
                            > 
                                <!--Group header-->
                                <template v-slot:group.header="{ headers, items }">
                                    <th :colspan="headers.length" class="indigo lighten-5" style="height: 36px">
                                        {{ items[0].instruction_work_center.work_center_name }}
                                    </th>
                                </template>
                                <!-- Assemblies slot -->
                                <template v-slot:item.product_assembly_data="{ item }">
                                    {{ item.product_assembly_data }}
                                </template>
                                <!--Type slot-->
                                <template v-slot:item.instruction_type="{ item }">
                                    <v-chip small>{{ formatInstructionTypeText(item.instruction_type) }}</v-chip>
                                </template>
                                <!-- Actions column slot -->
                                <template v-slot:item.actions="{ item }">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="printInstruction(item)">
                                                <v-list-item-title>
                                                    <v-icon small left>mdi-printer</v-icon> Print Instruction
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import db from '../../../components/firebaseInit';
    import { jsPDF } from 'jspdf';
    import 'jspdf-autotable';

    export default {
        name: "ProductionInstructions",
        data() {
            return {
                search: '',
                workCenters: [],
                selectedWorkCenters: [],
                production_instructions: [],
                tableHeaders: [
                    { text: 'Instruction #', value: 'instruction_number' },
                    { text: 'Date Issued', value: 'instruction_date_issued' },
                    { text: 'Work Center', value: 'instruction_work_center.work_center_name' },
                    { text: 'Product / Assemblies', value: 'product_assembly_data' },
                    { text: 'Type', value: 'instruction_type' },
                    { text: 'Status', value: 'instruction_status' },
                    { text: 'Actions', value: 'actions', align: "right" },
                ]
            }
        },
        computed: {
            filteredInstructions() {
                return this.production_instructions.filter(instruction => {
                    // Search filter
                    const searchLower = this.search.toLowerCase();
                    const matchesSearch = Object.keys(instruction)
                        .some(key => String(instruction[key]).toLowerCase().includes(searchLower));

                    // Work center filter
                    const matchesWorkCenter = this.selectedWorkCenters.length
                        ? this.selectedWorkCenters.includes(instruction.instruction_work_center.work_center_name)
                        : true;

                    return matchesSearch && matchesWorkCenter;
                });
            },
        },
        methods: {
            async getProductionInstructions() {
                const docRef = db.collection("production_instructions").where("instruction_status", "==", "Created").orderBy("instruction_number");
                try {
                    const snapshot = await docRef.get();
                    this.production_instructions = snapshot.docs.map(doc => {
                        const data = doc.data();
                        // Extract unique work centers for the select filter
                        const workCenterName = data.instruction_work_center.work_center_name;
                        if (!this.workCenters.includes(workCenterName)) {
                            this.workCenters.push(workCenterName);
                        }
                        //

                        let product_assembly_data;

                        if(data.instruction_type === "ManualAssembly") {
                            product_assembly_data = this.formatAssemblyListAsString(data.instruction_assemblies)
                        } else if (data.instruction_type === "ManualProduct") {
                            product_assembly_data = data.product_name;
                        } else if (data.instruction_type === "ProductionPlan") {
                            product_assembly_data = data.production_plan_number
                        } else {
                            product_assembly_data = "Other type";
                        }

                        return { ...data, product_assembly_data };
                    });
                } catch (error) {
                    console.error("Error fetching production instructions:", error);
                }
            },
            //
            formatAssemblyListAsString(assemblies) {
                return assemblies.map(assembly => assembly.assembly_name).join(', ');
            },
            //
            formatInstructionTypeText(type) {
                return type.split("Manual")[1];
            },
            //
            printInstruction(item) {
                if (item.instruction_type === "ManualAssembly") {
                    this.printAssemblyInstruction(item);
                } else if (item.instruction_type === "ManualProduct") {
                    this.printProductInstruction(item);
                }
            },
            //
            printAssemblyInstruction(item) {
                const doc = new jsPDF();

                item.instruction_assemblies.forEach((assembly, index) => {
                    if (index > 0) doc.addPage();  // Add a new page for each additional assembly

                    // Add Production Instruction title
                    doc.setFontSize(20);
                    doc.setFont(undefined, 'bold');
                    doc.text(`INSTRUCTION - ${item.instruction_number}`, 105, 20, null, null, 'center');  // Centered based on A4 page width

                    // Highlight the Work Center Name
                    doc.setFontSize(16); // Larger font size for prominence
                    doc.setFont(undefined, 'bold');
                    doc.setTextColor(0, 0, 0); // Black color for emphasis
                    doc.text(`Work Center: ${item.instruction_work_center.work_center_name}`, 105, 35, null, null, 'center');

                    // Display the Date Issued prominently
                    doc.setFontSize(12); // Smaller than work center but still prominent
                    doc.setFont(undefined, 'normal');
                    doc.setTextColor(0, 0, 0); // Reset to black for regular text
                    doc.text(`Date Issued: ${item.instruction_date_issued}`, 105, 45, null, null, 'center');  // Positioned under the work center name

                    // Display Assembly details
                    doc.autoTable({
                        startY: 55,
                        theme: 'plain',
                        head: [['Assembly Name', 'Assembly Number', 'Qty Required']],
                        body: [[
                            assembly.assembly_name,
                            assembly.assembly_number,
                            assembly.assembly_quantity.toString()
                        ]],
                        styles: { halign: 'center' }
                    });

                    // Prepare parts data and determine columns to exclude
                    let columns = [
                        { key: 'part_name', header: 'NAME' },
                        { key: 'part_material.material_item_name', header: 'MATERIAL' },
                        { key: 'part_uom', header: 'UoM' },
                        { key: 'part_thickness', header: 'THICKNESS' },
                        { key: 'part_width', header: 'WIDTH' },
                        { key: 'part_length', header: 'LENGTH' },
                        //{ key: 'part_quantity', header: 'QTY / ASSEMBLY' },
                        { key: 'total_quantity', header: 'TOTAL QTY' }
                    ];

                    const nonEmptyColumns = columns.filter(col => {
                        return assembly.parts.some(part => {
                            const value = col.key.split('.').reduce((acc, curr) => acc && acc[curr], part);
                            return value !== null && value !== undefined && value.toString().trim() !== '';
                        });
                    });

                    let filteredHeaders = nonEmptyColumns.map(col => col.header);
                    let filteredBody = assembly.parts.map(part => nonEmptyColumns.map(col => {
                        const value = col.key.split('.').reduce((acc, curr) => acc && acc[curr], part);
                        return value.toString();
                    }));

                    // Parts list with dynamic column removal
                    doc.autoTable({
                        startY: doc.lastAutoTable.finalY + 10,
                        theme: 'grid',
                        styles: { halign: 'center', valign: 'middle' },
                        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Dark grey header
                        head: [filteredHeaders],
                        body: filteredBody
                    });

                    // Add page number
                    doc.setFontSize(10);
                    doc.text(`Page ${index + 1} of ${item.instruction_assemblies.length}`, 105, 280, null, null, 'center');
                });

                // Save the PDF
                doc.save(`Instruction-${item.instruction_number}.pdf`);
            },
            //
            printProductInstruction(item) {
                const doc = new jsPDF();

                item.instruction_assemblies.forEach((assembly, index) => {
                    if (index > 0) doc.addPage();

                    // Make new table for each assembly
                    const result = Object.groupBy(assembly.parts, ({ assembly_name }) => assembly_name); 

                    Object.entries(result).forEach((entry, i) => {
                        if (i > 0) doc.addPage();

                        const assemblyName = entry[0];
                        const assemblyData = entry[1];

                        // Add Production Instruction title
                        doc.setFontSize(20);
                        doc.setFont(undefined, 'bold');
                        doc.text(`INSTRUCTION - ${item.instruction_number}`, 105, 20, null, null, 'center');  // Centered based on A4 page width

                        // Highlight the Work Center Name
                        doc.setFontSize(16); // Larger font size for prominence
                        doc.setFont(undefined, 'bold');
                        doc.setTextColor(0, 0, 0); // Black color for emphasis
                        doc.text(`Work Center: ${item.instruction_work_center.work_center_name}`, 105, 35, null, null, 'center');

                        // Display the Date Issued prominently
                        doc.setFontSize(12); // Smaller than work center but still prominent
                        doc.setFont(undefined, 'normal');
                        doc.setTextColor(0, 0, 0); // Reset to black for regular text
                        doc.text(`Date Issued: ${item.instruction_date_issued}`, 105, 45, null, null, 'center');  // Positioned under the work center name

                        // Display Product Details
                        doc.autoTable({
                            startY: 55,
                            theme: 'plain',
                            head: [['Product Name', 'Qty Required', 'Assembly Name']],
                            body: [[
                                item.product_name,
                                item.product_quantity,
                                assemblyName,
                            ]],
                            styles: { halign: 'center' }
                        });

                        // Prepare parts data and determine columns to exclude
                        let columns = [
                            { key: 'part_name', header: 'NAME' },
                            { key: 'part_material.material_item_name', header: 'MATERIAL' },
                            { key: 'part_uom', header: 'UoM' },
                            { key: 'part_thickness', header: 'THICKNESS' },
                            { key: 'part_width', header: 'WIDTH' },
                            { key: 'part_length', header: 'LENGTH' },
                            { key: 'total_quantity', header: 'TOTAL QTY' }
                        ];

                        const nonEmptyColumns = columns.filter(col => {
                            return assemblyData.some(part => {
                                const value = col.key.split('.').reduce((acc, curr) => acc && acc[curr], part);
                                return value !== null && value !== undefined && value.toString().trim() !== '';
                            });
                        });

                        let filteredHeaders = nonEmptyColumns.map(col => col.header);
                        let filteredBody = assemblyData.map(part => nonEmptyColumns.map(col => {
                            const value = col.key.split('.').reduce((acc, curr) => acc && acc[curr], part);
                            return value.toString();
                        }));

                        // Parts list with dynamic column removal
                        doc.autoTable({
                            startY: doc.lastAutoTable.finalY + 10,
                            theme: 'grid',
                            styles: { halign: 'center', valign: 'middle' },
                            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Dark grey header
                            head: [filteredHeaders],
                            body: filteredBody
                        });

                        // Add page number
                        doc.setFontSize(10);
                        doc.text(`Page ${index + 1} of ${item.instruction_assemblies.length}`, 105, 280, null, null, 'center');
                    


                    }); // END of entries loop

                }); // END of foreach loop

                // Save the PDF
                doc.save(`Instruction-${item.instruction_number}.pdf`);
            },
        },
        created() {
            this.getProductionInstructions();
        },
    }
</script>