var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card-title',[_c('h2',[_vm._v("Production Instructions")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/new-production-instructions"}},[_vm._v("New Instruction "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.workCenters,"item-text":"work_center_name","item-value":"work_center_name","label":"Filter by Work Center","return-object":"","clearable":"","prepend-icon":"mdi-filter-variant","outlined":"","dense":"","multiple":""},model:{value:(_vm.selectedWorkCenters),callback:function ($$v) {_vm.selectedWorkCenters=$$v},expression:"selectedWorkCenters"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.filteredInstructions,"headers":_vm.tableHeaders,"items-per-page":500,"show-expand":"","single-expand":"","item-key":"instruction_id","group-by":"instruction_work_center.work_center_name"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('th',{staticClass:"indigo lighten-5",staticStyle:{"height":"36px"},attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(items[0].instruction_work_center.work_center_name)+" ")])]}},{key:"item.product_assembly_data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_assembly_data)+" ")]}},{key:"item.instruction_type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.formatInstructionTypeText(item.instruction_type)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.printInstruction(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-printer")]),_vm._v(" Print Instruction ")],1)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }