<template>
    <v-container  fluid>
        <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
            <v-col lg="8" offset-lg="2">
                <v-card-title>
                    <v-btn to="/production-instructions" color="#ffffff" light elevation="0">
                        <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>New Production Instructions</h3>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card elevation="0">
                    <InstructionTypeSelector
                        :instructionType="instruction_type"
                        @updateInstructionType="handleInstructionTypeUpdate($event)"
                    />
                    <div v-if="instruction_type">
                        <!-- Render the production instruction form based on the type selected -->
                        <component :is="currentComponent" />
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
    import InstructionTypeSelector from "../components/InstructionTypeSelector.vue";
    import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
    export default {
        name: "NewProductionInstructions",
        mixins: [mixin_UserRoles],
        components: {
            InstructionTypeSelector,
            InstructionFormManualAssembly: () => import(/* webpackChunkName: "productionPlans" */'../components/InstructionFormManualAssembly.vue'),
            InstructionFormManualProduct: () => import(/* webpackChunkName: "productionPlans" */'../components/InstructionFormManualProduct.vue'),
            InstructionFormProductionPlan: () => import(/* webpackChunkName: "productionPlans" */'../components/InstructionFormProductionPlan.vue'),
        },
        data() {
            return {
                instruction_type: "ManualAssembly",
            }
        },
        computed: {
            currentComponent() {
                switch (this.instruction_type) {
                    case 'ManualAssembly':
                        return 'InstructionFormManualAssembly';
                    case 'ManualProduct':
                        return 'InstructionFormManualProduct';
                    case 'ProductionPlan':
                        return 'InstructionFormProductionPlan';
                    default:
                        return null; // No component for undefined or null types
                }
            }
        },
        methods: {
            handleInstructionTypeUpdate(type) {
                this.instruction_type = type;
            }
        },
    }
</script>

// Option 1: [Manual Instruction] Choose an assembly + quantity --> choose which related work centers to include in the instructions
// Oprion 2: [From Production Plans] Choose a selection of production plans --> choose which related work centers to include in the instructions
// Option 3: [For Product] Choose a product + quantity --> choose which related work centers to include in the instructions